export function define(tagName: string, template: HTMLTemplateElement): void {
  customElements.define(tagName, class extends HTMLElement {
    constructor() {
      super();
      this.attachShadow({ mode: 'open' });
    }

    connectedCallback(): void {
      if (!this.shadowRoot.firstChild)
        this.shadowRoot.append(template.content.cloneNode(true));
    }
  });
}
