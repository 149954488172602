import type { KghDonationsResponse } from '../../functions/config';
import type { PropertyValues, TemplateResult } from 'lit';

import { LitElement, html } from 'lit';

import { customElement, property, query } from 'lit/decorators.js';

import { handleAsJson } from '../lib/fetch';

import styles from './kgh-campaign.css';
import shared from './shared-styles.css';

import bound from 'bind-decorator';

@customElement('kgh-campaign')
export class KghCampaign extends LitElement {
  static readonly styles = [shared, styles];

  static readonly formatter = new Intl.NumberFormat('en-US');

  @property({ type: String, attribute: 'campaign-id' }) campaignId: string;

  @property({ type: Number, attribute: 'goal-usd', reflect: true }) goalUSD: number;

  @property({ type: Number, reflect: true }) amount: number;

  @query('progress') progress: HTMLProgressElement;

  render(): TemplateResult {
    const { formatter } = KghCampaign;
    return html`
      <slot></slot>
      <article>
        <span id="amount" aria-hidden="${this.amount <= 0}">
          $${formatter.format(this.amount)}
        </span>
        <progress id="progress" max="${this.goalUSD}" role="presentation"></progress>
        <span id="goal" ?hidden="${this.goalUSD == null}" aria-hidden="${this.amount <= 0}">
          out of $${formatter.format(this.goalUSD ?? 0)}
        </span>
      </article>
    `;
  }

  updated(changed: PropertyValues<this>): void {
    if (changed.has('amount'))
      this.amountChanged();
  }

  connectedCallback(): void {
    super.connectedCallback();
    document.addEventListener('payment-success', this.onPaymentSuccess);
    if (!this.amount)
      this.getCampaign();
  }

  @bound private onPaymentSuccess(event: CustomEvent): void {
    if (typeof event.detail?.amount !== 'number')
      return;
    this.amount = this.amount + (event.detail.amount / 100);
  }

  private amountChanged(): void {
    if (this.progress)
      this.progress.value = this.amount;
  }

  private getCampaignURL(): string {
    const url = new URL('/donations', location.origin);

    url.searchParams.set('campaign-id', this.campaignId);

    return url.toString();
  }

  private async fetchCampaign(): Promise<KghDonationsResponse> {
    try {
      return await fetch(this.getCampaignURL()).then(handleAsJson) as KghDonationsResponse;
    } catch {
      return null;
    }
  }

  private async getCampaign(): Promise<void> {
    const campaign = await this.fetchCampaign();
    if (campaign) {
      this.amount = campaign.sum / 100;
      this.goalUSD = campaign.goalUSD;
      this.dispatchEvent(new CustomEvent('amount-updated'));
    }
  }
}
