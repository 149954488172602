import { customElement } from 'lit/decorators.js';
import { define } from './define';

/**
 * Defines a Custom Element from a name and template
 * @element
 * @attr name - the name of the custom-element
 */
@customElement('define-element')
export class DefineElement extends HTMLElement {
  private mo: MutationObserver;

  private get template(): HTMLTemplateElement {
    return this.firstElementChild instanceof HTMLTemplateElement ? this.firstElementChild : null;
  }

  get name(): string {
    return this.getAttribute('name');
  }

  protected connectedCallback(): void {
    if (this.template) define(this.name, this.template);
    else {
      this.mo = new MutationObserver(() => {
        if (!this.template) return;
        this.mo.disconnect();
        define(this.name, this.template);
      });
      this.mo.observe(this, { childList: true });
    }
  }
}
