import { initializeApp } from 'firebase/app';
import { initializeAppCheck, getToken, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

// @ts-expect-error: this is fine
self.FIREBASE_APPCHECK_DEBUG_TOKEN = __FIREBASE_APPCHECK_DEBUG_TOKEN;

export const app = initializeApp({
  apiKey: 'FIREBASE_API_KEY',
  appId: 'FIREBASE_APP_ID',
  projectId: 'FIREBASE_PROJECT_ID',
});

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('RECAPTCHA_ENTERPRISE_SITE_KEY'),
  isTokenAutoRefreshEnabled: true,
});

// GDPR opt-out
appCheck.app.automaticDataCollectionEnabled = false;

export async function getAppCheckToken(): Promise<string> {
  const { token } = await getToken(appCheck);
  return token;
}
