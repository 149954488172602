import './lib/captcha.js';
import './components/malar-key.js';
import './components/kgh-donation.js';
import './components/kgh-campaign.js';
import './components/define-element.js';

const stories = document.getElementById('stories');

declare global {
  interface Window {
    WebComponents: {
      waitFor(onResolve: () => void): void;
    };
  }
}

if (window.WebComponents) {
  window.WebComponents.waitFor(function resolveBody() {
    document.body.removeAttribute('unresolved');
  });
}

const onToggle = (): void => {
  if (stories.querySelector('[open]')) stories.setAttribute('open', '');
  else stories.removeAttribute('open');
};

function closeEl(el: HTMLDetailsElement): void {
  el.open = false;
}

function hookUpDetails(el: HTMLDetailsElement): void {
  el.addEventListener('toggle', onToggle);
}

function hookUpHideButton(el: HTMLElement): void {
  el.addEventListener('click', (): void => {
    stories.querySelectorAll('details').forEach(closeEl);
  });
}

function hookUpShowButton(el: HTMLElement): void {
  el.addEventListener('click', (): void => {
    stories.querySelectorAll('details').forEach(closeEl);
    el.closest('details').open = true;
  });
}

if (stories) {
  stories.querySelectorAll('details').forEach(hookUpDetails);
  stories.querySelectorAll('.hide-button').forEach(hookUpHideButton);
  stories.querySelectorAll('.show-button').forEach(hookUpShowButton);
}
